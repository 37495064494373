<template>
  <div class="photos-content">
    <img
      v-for="file in files"
      :key="file.id"
      :src="returnSrcFile(file.path)"
      alt="img"
      @click="switchPopupImage({ file: file, show: true })"
    />
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions } from "vuex";

export default {
  name: "PhotosContent",
  mixins: [],
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["switchPopupImage"]),
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.photos-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  margin-bottom: 60px;
  @media screen and (max-width: $xs) {
    margin-bottom: 0;
  }
  img {
    width: 49%;
    height: 122px;
    object-fit: cover;
    margin-bottom: 5px;
    border-radius: 4px;
    cursor: pointer;
  }
  img:nth-of-type(3n) {
    width: 100%;
  }
}
</style>
